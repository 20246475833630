import NCheckbox from "../inputs/n-checkbox.js";

class EVENTS {
}

EVENTS.CHECK = 'on-check'
EVENTS.CHECK_ALL = 'on-check-all'
EVENTS.CLICK = 'on-click'

export default {
    props: {
        expandable: Boolean,
        checkableTd: Boolean,
        checkableTh: Boolean,
        border: {
            type: Boolean,
            default: true
        }
    },
    components: {
        'n-checkbox': NCheckbox
    },
    emits: Object.values(EVENTS),
    data() {
        return {};
    },
    methods: {
        getRowClass() {

            let rowClass = 'expandable-row'
            if (!this.expandable) {
                rowClass = 'unexpandable-row'
            }

            if (!this.border) {
                rowClass += ' no-border'
            }

            return rowClass
        },
        check(ev) {
            this.$emit(EVENTS.CHECK, ev)
            this.$emit(EVENTS.CHECK_ALL, ev)
        },
        expand() {
            this.$emit(EVENTS.CLICK)
        }
    },
    mounted() {
    },
    template: `
        <tr v-bind="$attrs" :class="getRowClass()"  @click="expand">
            <td v-if="checkableTd">
                <n-checkbox @click="check"></n-checkbox>
            </td>
             <th v-if="checkableTh">
                <n-checkbox @click="check"></n-checkbox>
            </th>
            <slot name="main-content"></slot>
        </tr>
        <tr v-if="expandable" class="expanded-row">
                <slot name="expanded-content"></slot>
        </tr>
    `,
};