import NButton from "../buttons/n-button.js";
import NIconButton from "../buttons/n-icon-button.js";

class EVENTS {
}

EVENTS.REMOVE_FILES = 'file-removed'
EVENTS.ADD_FILES = 'file-added'

const allowedFileTypes = ['png', 'jpg', 'jpeg', 'webp', 'tif', 'tiff', 'jfif', 'bmp']

export default {
    emits: Object.values(EVENTS),
    components: {
        "n-button": NButton,
        "n-icon-button": NIconButton,
    },
    props: {
        initialFiles: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            files: [],
        };
    },
    methods: {
        handleFileUpload(event) {
            const uploadedFiles = Array.from(event.target.files).map(file => ({
                url: URL.createObjectURL(file),
                file,
            }));

            this.files.push(...uploadedFiles);
            this.$emit('file-added', uploadedFiles.map(f => f.file));
        },
        removeFile(index) {
            const removedFile = this.files.splice(index, 1)[0];
            this.$emit('file-removed', removedFile.file);
        },
        handleInputClick() {
            this.$refs.inputRef.click()
        },
    },
    mounted() {
        this.files = this.initialFiles.map(file => ({
            url: URL.createObjectURL(file),
            file,
        }));
    },
    template: `
    <n-button style="width:calc(50% - 1rem)" outlined small @click="handleInputClick" text="Upload Photo" icon-right="fa-sharp fa-solid fa-camera">
        <input hidden ref="inputRef" type="file" accept="image/*" capture="camera" @change="handleFileUpload" multiple>
    </n-button>
    <div v-if="files?.length > 0" class="image-preview-container" >
        <div v-for="(file, index) in files" :key="index" class="image-preview">
        <img :src="file.url"  class="preview-img">
        <n-icon-button style="position: absolute; top:0; right:0; color:#F9524C !important" small outlined color="transparent"   @click="removeFile(index)" icon-class="fa-sharp fa-solid fa-trash"></n-icon-button>
        </div>
    </div>
    `,
}