const ADDRESS_TYPE_PRIORITY = {
    'ROOFTOP': 1,
    'RANGE_INTERPOLATED': 2,
    'GEOMETRIC_CENTER': 3,
    'APPROXIMATE': 4
}
// Google doesn't like proxies, so Global variable for clusters
let markerClusterer = null
export default {
    data() {
        return {}
    },
    inject: ["mapController"],
    template: `
      <div id="map" style="position: absolute; inset:0"></div>`,
    props: {
        preferredCenter: {
            type: Object,
            default: undefined
        },
    },
    methods: {
        createMarker(result) {
            let latitude = result.geometry.location.lat()
            let longitude = result.geometry.location.lng()
            let postal_address = result.formatted_address
            let marker = {
                lng: longitude,
                lat: latitude,
                postal_address: postal_address,
            }
            return new BaseMarkers(marker)
        },
        setupCurrentMarker(marker, icon_url = Constants.Map.MARKER_INACTIVE_ICON) {
            this.mapController.userAddedGoogleMarker = new MapMarker(marker)
            this.mapController.userAddedGoogleMarker.googleIconMarker = new google.maps.Marker({
                icon: {url: icon_url},
                draggable: true,
                map: this.map,
            })


            google.maps.event.addListener(this.mapController.userAddedGoogleMarker.googleIconMarker, 'click', () => {
                this.mapController.removeCurrentMarker()
            })

            google.maps.event.addListener(this.mapController.userAddedGoogleMarker.googleIconMarker, 'dragend', () => {
                this.resolveAddress()
            })
            google.maps.event.addListener(this.mapController.userAddedGoogleMarker.googleIconMarker, 'mouseover', () => {
                // show info window
                this.mapController.userAddedGoogleMarker.popup = new google.maps.InfoWindow()
                this.mapController.userAddedGoogleMarker.popup.setContent(this.mapController.userAddedGoogleMarker.postal_address || this.mapController.userAddedGoogleMarker.name)
                this.mapController.userAddedGoogleMarker.popup.open(this.map, this.mapController.userAddedGoogleMarker.googleIconMarker)
            })
            google.maps.event.addListener(this.mapController.userAddedGoogleMarker.googleIconMarker, 'mouseout', () => {
                // hide info window
                this.mapController.userAddedGoogleMarker.popup && this.mapController.userAddedGoogleMarker.popup.close()
            })
        },
        updateMarker(marker, icon_url = null) {
            if (!icon_url) {
                icon_url = this.mapController.userAddedGoogleMarker.googleIconMarker.icon.url
            }
            this.mapController.userAddedGoogleMarker.postal_address = marker.postal_address
            this.mapController.userAddedGoogleMarker.lng = marker.lng
            this.mapController.userAddedGoogleMarker.lat = marker.lat
            if (this.mapController.userAddedGoogleMarker.googleIconMarker) {
                this.mapController.userAddedGoogleMarker.googleIconMarker.setPosition(marker.coords)
                this.mapController.userAddedGoogleMarker.googleIconMarker.setMap(this.map)
            }
            if (!this.mapController.userAddedGoogleMarker.googleIconMarker) {
                this.mapController.userAddedGoogleMarker.googleIconMarker = new google.maps.Marker({
                    position: marker.coords,
                    icon: {url: icon_url},
                    draggable: true,
                    map: this.map,
                })
            }
        },
        setCurrentMapMarker(marker, icon_url = Constants.Map.MARKER_INACTIVE_ICON) {
            if (!this.map)
                return
            if (this.mapController.userAddedGoogleMarker.latitude) {
                this.updateMarker(marker)
                return
            }
            this.setupCurrentMarker(marker, icon_url)
        },
        setMapMarkers(markers) {
            if (!this.map)
                return

            markers.forEach((marker) => {
                const activeMarkerIcon = marker.type === MARKER_TYPES.NON_EXISTING_BUILDING ? Constants.Map.MARKER_ACTIVE_ICON_NON_EXISTING : Constants.Map.MARKER_ACTIVE_ICON_EXISTING
                let url = marker.toggled ? activeMarkerIcon : Constants.Map.MARKER_INACTIVE_ICON

                if (marker.added) {
                    url = Constants.Map.MARKER_CONNECTED_BUILDING_ICON
                }

                if (!marker.googleIconMarker) {
                    marker.googleIconMarker = new google.maps.Marker({
                        position: marker.coords,
                        icon: {url: url},
                        draggable: false,
                    })
                }

                // add a listener for each marker
                if (marker.type === MARKER_TYPES.NON_EXISTING_BUILDING) {
                    google.maps.event.addListener(marker.googleIconMarker, 'click', () => {
                        this.mapController.toggleMarker(marker)
                        this.deactivateUserAddedMarker(marker)
                    })
                    google.maps.event.addListener(marker.googleIconMarker, 'mouseover', () => {
                        marker.googleIconMarker.setIcon(Constants.Map.MARKER_ACTIVE_ICON_NON_EXISTING_REMOVE)
                        marker.popup = new google.maps.InfoWindow()
                        marker.popup.setContent(marker.name || marker.property_id || marker.postal_address)
                        marker.popup.open(this.map, marker.googleIconMarker)
                    })
                    google.maps.event.addListener(marker.googleIconMarker, 'mouseout', () => {
                        marker.googleIconMarker.setIcon(Constants.Map.MARKER_ACTIVE_ICON_NON_EXISTING)
                        marker.popup && marker.popup.close()
                    })
                }

                if (marker.type === MARKER_TYPES.EXISTING_BUILDING) {
                    google.maps.event.addListener(marker.googleIconMarker, 'mouseover', () => {
                        // show info window
                        marker.popup = new google.maps.InfoWindow()
                        marker.popup.setContent(marker.name || marker.property_id || marker.postal_address)
                        marker.popup.open(this.map, marker.googleIconMarker)
                    })
                    google.maps.event.addListener(marker.googleIconMarker, 'mouseout', () => {
                        // hide info window
                        marker.popup && marker.popup.close()
                    })

                    if (marker.added) {
                        return
                    }

                    google.maps.event.addListener(marker.googleIconMarker, 'click', () => {
                        //needed to persist the toggled state on the real mapController.mapMarkers as 'markers' being passed
                        //in to this method is a dereferenced variant of mapController.mapMarkers
                        this.mapController.toggleMarker(marker)
                        // marker.toggled = !marker.toggled

                        if (marker.toggled) {
                            this.activateMarker(marker)
                            return
                        }

                        this.deactivateMarker(marker)
                    })
                }
            })

            const clusterOptions = {
                fitToMarkers: true,
                styles: [
                    Constants.Map.MARKER_CLUSTER_STYLE_M1,
                    Constants.Map.MARKER_CLUSTER_STYLE_M2,
                    Constants.Map.MARKER_CLUSTER_STYLE_M3,
                    Constants.Map.MARKER_CLUSTER_STYLE_M4
                ]
            }

            //needed to reset the maps cluster
            if (markerClusterer) {
                markerClusterer.clearMarkers()
                markerClusterer = null
            }

            markerClusterer = new MarkerClusterer(this.map,
                markers.map(i => i.googleIconMarker),
                clusterOptions
            )
        },
        activateMarker(marker) {
            marker.toggled = true
            const activeMarkerIcon = marker.type === MARKER_TYPES.NON_EXISTING_BUILDING ? Constants.Map.MARKER_ACTIVE_ICON_NON_EXISTING : Constants.Map.MARKER_ACTIVE_ICON_EXISTING
            marker.googleIconMarker && marker.googleIconMarker.setIcon(activeMarkerIcon)

            this.mapController.removeCurrentMarker()
            // add to the selected markers list
            this.mapController.addSelectedMarker(marker)
        },
        deactivateMarker(marker) {

            marker.toggled = false
            const realMarker = this.mapController.getRealMarker(marker)
            if (realMarker) {
                realMarker.toggled = false
            }
            marker.googleIconMarker && marker.googleIconMarker.setIcon(Constants.Map.MARKER_INACTIVE_ICON)
            marker.popup && marker.popup.close()
            // remove from the selected markers list
            this.mapController.removeSelectedMarker(marker)
        },
        deactivateUserAddedMarker(marker) {
            marker.toggled = false
            marker.popup && marker.popup.close()
            // remove from the selected markers list
            this.mapController.deactivateUserAddedMarker(marker)
        },
        selectMarkerOnMap(marker, callback) {
            //[n-map] - selectMarkerOnMap function NEVER INVOKED
            if (marker.coords) {
                this.map.panTo(marker.coords)
                this.map.setZoom(this.mapController.selectedMarkerZoom)
            }
            if (callback) {
                // call the callback when panTo and setZoom are finished
                google.maps.event.addListenerOnce(this.map, 'idle', () => {
                    callback()
                })
            }
        },
        findAddress(searchKey, userAddedGoogleMarker) {
            let address = searchKey
            let _mapMarker = userAddedGoogleMarker
            let self = this
            this.geocoder.geocode({'address': address}, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    results.sort(function (a, b) {
                        return ADDRESS_TYPE_PRIORITY[a.geometry.location_type] - ADDRESS_TYPE_PRIORITY[b.geometry.location_type]
                    })
                    self.fitMapToBounds(results[0].geometry?.viewport)
                    let marker = self.createMarker(results[0])
                    self.setCurrentMapMarker({
                        ...marker,
                        type: MARKER_TYPES.NON_EXISTING_BUILDING
                    }, Constants.Map.MARKER_USER_ICON)
                    let loc = results[0].geometry.location

                    self.mapController.userAddedGoogleMarker.googleIconMarker.setPosition({
                        lat: loc.lat(),
                        lng: loc.lng()
                    })

                    if (results[0]) {
                        self.mapController.updateCurrentUserMarker(results[0], loc.lat(), loc.lng())
                    }
                }
            })
        },
        refreshMap() {
            let markers = this.mapController.mapMarkers
            if (this.mapController.selectedMarkers.length) {
                markers = this.mapController.selectedMarkers
            }

            if (this.searchKey !== '') {
                markers = this.mapController.filteredMarkers
            }

            this.refreshMapBounds(markers)
        },
        setMapBounds(markers) {
            if (!this.map)
                return

            let bounds = new google.maps.LatLngBounds()

            if (markers.length === 0) {
                bounds.extend(this.preferredCenter || Constants.Map.DEFAULT_CENTER)
            }

            for (let marker of markers) {
                bounds.extend(marker.coords)
            }

            google.maps.event.addListenerOnce(this.map, 'idle', () => {
                this.mapController.isBoundsChanged = true
                this.map.fitBounds(bounds)
                this.map.setZoom(this.mapController.mapDefaultZoom)
            })
        },
        refreshMapBounds(markers) {
            // get preselected markers
            if (!markers) {

                if (this.mapController.selectedMarkers.length) {
                    markers = this.mapController.selectedMarkers
                } else {
                    markers = this.mapController.mapMarkers
                }
                return
            }

            this.map.setZoom(this.mapController.mapDefaultZoom)
            this.setMapBounds(markers)
        },
        fitMapToBounds(viewport) {
            if (viewport) {
                this.map.fitBounds(viewport)
                this.map.setZoom(this.map.zoom + 1)
            }
        },
        resolveAddress() {
            let latlng = {
                lat: parseFloat(this.mapController.userAddedGoogleMarker.googleIconMarker.getPosition().lat()),
                lng: parseFloat(this.mapController.userAddedGoogleMarker.googleIconMarker.getPosition().lng()),
            }
            this.geocoder.geocode({location: latlng})
                .then((response) => {
                    response.results.sort(function (a, b) {
                        return ADDRESS_TYPE_PRIORITY[a.geometry.location_type] - ADDRESS_TYPE_PRIORITY[b.geometry.location_type]
                    })
                    if (response.results[0]) {
                        this.mapController.updateCurrentUserMarker(response.results[0], latlng.lat, latlng.lng)
                    }
                })
                .catch((e) => console.log("Geocoder failed due to: " + e))

        },
        initMap() {
            // initialization of Map
            let mapEl = document.getElementById('map')
            if (!mapEl)
                return

            // create the map
            this.map = new google.maps.Map(mapEl, {
                center: this.preferredCenter || Constants.Map.DEFAULT_CENTER,
                zoom: Constants.Map.DEFAULT_ZOOM,
                styles: [{
                    featureType: 'poi.business',
                    stylers: [{visibility: 'off'}],
                },
                    {
                        featureType: 'poi',
                        stylers: [{visibility: 'off'}],
                    }]
            })

            // initialization of Geocoder
            this.geocoder = new google.maps.Geocoder()

            // initialization of Google Maps Autocomplete
            let input = document.getElementById('searchAddressField')
            if (!input) console.log('No input field found for autocomplete')
            const options = {
                fields: ["address_components", "formatted_address", "geometry", "plus_code"],
                types: ["address"],
                strictBounds: false,
            }
            this.autocomplete = new google.maps.places.Autocomplete(input, options)

            // add event listeners
            // Bias the Autocomplete results towards current map's viewport.
            this.map.addListener("bounds_changed", () => {
                this.autocomplete.setBounds(this.map.getBounds())
            })

            this.map.addListener('click', (event) => {
                this.placeMarker(event.latLng)
            })

            this.autocomplete.addListener("place_changed", () => {
                const place = this.autocomplete.getPlace()
                if (!place) {
                    return
                }
                // For each place, get the icon, name and location.
                const bounds = new google.maps.LatLngBounds()

                if (!place.geometry || !place.geometry.location) {
                    return
                }

                this.mapController.searchKey = place.formatted_address
                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport)
                } else {
                    bounds.extend(place.geometry.location)
                }

                this.findAddress(this.mapController.searchKey, this.mapController.userAddedGoogleMarker)
            })

            if (this.mapController.mapMarkers.length > 0) {
                this.mapController.initialiseMarkers(this.mapController.mapMarkers)
            }
        },
        placeMarker(location) {
            this.setCurrentMapMarker({
                ...location,
                type: MARKER_TYPES.NON_EXISTING_BUILDING
            }, Constants.Map.MARKER_USER_ICON)

            this.mapController.userAddedGoogleMarker.googleIconMarker.setPosition(location)
            this.resolveAddress()
        }
    },
    created() {
        this.mapController.component = this
    },
    mounted() {
    }
}
