class EVENTS {
}

EVENTS.UPDATE_COLUMNS = 'update'

export default {
    props: {
        item: Number,
        type: String,
        approved: Boolean,
        rejected: Boolean,
    },
    emits: Object.values(EVENTS),
    inject: [
        'selectItem',
        'columnId',
    ],
    data() {
        return {};
    },
    methods: {
        onClick(event) {
            this.setSelectedItem()
            this.selectItem(event)
        },
        setSelectedItem() {
            const handler = pHandler.getOrRegisterParamBuilder(NORSE_FILTERS.CHECKLIST)
            handler.setChecklistParam(this.columnId, `${this.type}_${this.item}`)
        },
    },
    created() {
    },
    mounted() {
    },
    template: `
        <div ref="item" class="checklist-item" @click="onClick" :data-item="item">
            <div class="checklist-item-selected-marker"></div>
            <div class="checklist-item-content">
                <slot></slot>
                <div class="checklist-item-chevron fas fa-chevron-right"></div>
            </div>
            <div v-if="approved" class="checklist-item-handled-marker approved"></div>
            <div v-if="rejected" class="checklist-item-handled-marker rejected"></div>
        </div>
    `,
};