// Vue3DatePicker docs
//
// CURRENTLY USED PROPS
// placeholder ->> https://vue3datepicker.com/api/props/#placeholder
// modelType --> https://vue3datepicker.com/api/props/#modeltype
// cancelText --> https://vue3datepicker.com/api/props/#canceltext
// selectText --> https://vue3datepicker.com/api/props/#selecttext
// textInputOptions --> https://vue3datepicker.com/api/props/#textinputoptions
// disabled --> https://vue3datepicker.com/api/props/#disabled
// inputClassName --> https://vue3datepicker.com/api/props/#inputclassname
// state --> https://vue3datepicker.com/api/props/#state
// autoApply --> https://vue3datepicker.com/api/props/#autoapply
// closeOnAutoApply --> https://vue3datepicker.com/api/props/#closeonautoapply
// textInput --> https://vue3datepicker.com/api/props/#textinput
// locale --> https://vue3datepicker.com/api/props/#locale
// enableTimePicker --> https://vue3datepicker.com/api/props/#enabletimepicker
// @update:modelValue --> https://vue3datepicker.com/api/events/#update-modelvalue

import NTooltip from "../n-tooltip.js";

const EVENTS = {
    UPDATE_MODEL_VALUE: 'update:modelValue',
    CHANGE: 'change',
}

export default {
    inject: ['errors', 'current_device'],
    components: {
        'n-date-picker': VueDatePicker,
        'n-tooltip': NTooltip,
    },
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }

            this.initStyle()
            return false
        },
    },
    emits: Object.values(EVENTS),
    props: {
        modelValue: String,

        id: String,
        name: String,
        label: String,
        initial: String,
        helpText: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        placeholder: String,
        proposedTime: String,
        fullWidth: Boolean,
        inline: Boolean,
        hideLabel: Boolean,
        hideTooltip: Boolean,

        //VueDatepickerProps
        autoApply:
            {
                type: Boolean,
                default: true
            },
        allowTextInput:
            {
                type: Boolean,
                default: true
            },
    },
    data() {
        return {
            value: {hours: 7, minutes: 0}, //07:00
            isValid: null,
            error: null,
            TIME_PICKER_HEIGHT: 120, // in px
            //Vue3DatePicker data
            inputClass: null,
            cancelText: gettext('Cancel'),
            selectText: gettext('Select'),
            textInputOptions: {
                enterSubmit: true,
                tabSubmit: true,
                openMenu: true,
                format: (val) => this.onInput(val),
            },
            proposedTimeText: gettext('Click to apply proposed time'),
            validationState: null // set to false on error, reset to null when handled
        }
    },
    methods: {
        propagateValue(value) {
            this.error = null
            this.value = value
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, this.getStringFromTimeObject(value))
        },
        onChange(value) {
            this.$nextTick(() => {
                this.propagateValue(value)
                this.$emit(EVENTS.CHANGE, value)
                this.resetError()
            })
        },
        onInput(value) {
            const time = this.getTimeObjectFromString(value)

            const dateTime = new Date()
            dateTime.setHours(time.hours)
            dateTime.setMinutes(time.minutes)

            if (this.isValidTimeString(value)) {
                this.resetError()
                return dateTime
            }
            this.makeInvalid("Invalid time")
            return dateTime
        },
        initStyle() {
            this.labelClass = 'field-title'
            this.inputClass = 'datepicker time'
        },
        resetError() {
            this.error = ''
            this.inputClass = 'datepicker time'
        },
        makeInvalid(error) {
            this.error = error
            this.inputClass += ' error'
        },
        applyProposedTime() {
            if (this.isValidTimeString(this.proposedTime)) {
                //just in case guard
                this.resetError()
                this.propagateValue(this.getTimeObjectFromString(this.proposedTime))
                return
            }
            this.makeInvalid("Invalid time")
        },
        getTimeObjectFromString(string) {
            const timeString = moment(string, [Constants.TIME_FORMAT, 'HHmm']).format(Constants.TIME_FORMAT)
            const timeSplit = timeString.split(':')
            const hour = timeSplit[0] //HH
            const minutes = timeSplit[1] // mm
            return {hours: hour, minutes: minutes, seconds: 0}
        },
        getStringFromTimeObject(obj) {
            const {hours, minutes} = obj || {}
            const hourString = hours?.toString() || '00'
            const minuteString = minutes?.toString() || '00'

            return hourString + ':' + minuteString
        },
        isValidTimeString(time) {
            const isStrict = true
            return moment(time, [Constants.TIME_FORMAT, 'HHmm'], isStrict).isValid()
        },
        getContainerClass() {
            let containerClass = 'input-container'

            if (this.fullWidth) {
                containerClass += ' full-width'
            }

            return containerClass
        },
        getConfig() {
            return {
                'modeHeight': this.TIME_PICKER_HEIGHT,
                'closeOnAutoApply': true
            }

        },
        handleOpenPortal() {
            const modal = document.getElementsByClassName('n-modal-body')
            const modalContent = document.getElementsByClassName('modal-content')
            if (modal.length > 0) {
                modal[0].style.overflowY = 'unset'
            }
            if (modalContent.length > 0) {
                modalContent[0].style.overflow = 'unset'
            }
        },
        handleClosePortal() {
            const modal = document.getElementsByClassName('n-modal-body')
            const modalContent = document.getElementsByClassName('modal-content')
            if (modal.length > 0) {
                modal[0].style.overflowY = 'auto'
            }
            if (modalContent.length > 0) {
                modalContent[0].style.overflow = 'auto'
            }
        }
    },
    created() {
        this.initStyle()

        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if (this.initial) {
            let value = this.getTimeObjectFromString(this.initial)
            this.propagateValue(value)
            return
        }
        if (this.modelValue)
            this.value = this.getTimeObjectFromString(this.modelValue)
    },
    template: `
    <div :class="getContainerClass()" style="width:max-content">
        <div class="n-flex gap-m align-center" style="justify-content: space-between">
            <label  v-if="!hideLabel" :for="id" :class="labelClass">[[ label ]]</label>
            <div v-if="proposedTime" class="proposed-date-container" @click="applyProposedTime()">
                [[proposedTimeText]]: <p>[[proposedTime]]</p>
            </div>
            <n-tooltip v-if="!hideTooltip && helpText"  :help-text="helpText" :label="label" >
                <i class="fa-sharp fa-regular fa-circle-info"></i>
            </n-tooltip>
        </div>
        <n-date-picker 
        v-model="value" 
        :uid="id" 
        :name="name" 
        timePicker
        :placeholder="placeholder || '--:--'"
        modelType="format"
        :cancelText="cancelText"
        :selectText="selectText"
        :textInputOptions="textInputOptions"
        :disabled="disabled"
        :inputClassName="inputClass"
        :state="validationState"
        :autoApply="autoApply"
        :textInput="current_device !== 'MOBILE'" 
        :inline="inline"
        keep-action-row
        locale="sv" 
        :config="getConfig()"
        @update:modelValue="onChange(value)" 
        @open="handleOpenPortal"
        @closed="handleClosePortal"
        >
            <template #input-icon>
                <i class="fa fa-clock" style="margin-left: 1rem; width: 20px; height: 20px"></i>
            </template>
        </n-date-picker>
        <div v-if="error" class="invalid-field-error" >
        [[ error ]]
        </div>
    </div>
    `,
}